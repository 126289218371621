@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Custom Scrollbar Styles */
/* Track */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination-btn {
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
    color: #9f1239;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.active {
  background-color: #9f1239;
  color: #fff;
}

.pagination-ellipsis {
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: not-allowed;
  color: #ccc;
}

/* Loading CSS */
.loading-container {
  width: 100%;
  height: 100vh;
  background-color: #00000087;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loading-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff70;
}

.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: rgb(225 29 72);
  /* #3498db; */
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

/* ThemeSwitch.css */
.switch {
  position: relative;
  width: 55px;
  height: 25px;
  background-color: #ddd;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch .ball {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.5s;
}

.switch.dark {
  background-color: #333;
}

.ball.dark {
  background-color: #333;
}

.switch.dark .ball {
  transform: translateX(30px);
}

.switch.light .ball {
  transform: translateX(2px);
}
